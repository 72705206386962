//ICONS
import AccountCircle from '@material-ui/icons/AccountCircle';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ScheduleIcon from '@material-ui/icons/Schedule';
import LocationOnIcon from '@material-ui/icons/LocationOn';
/* import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import InfoIcon from '@material-ui/icons/Info'; */
import { FieldInputTypeDto } from './types/FieldInputTypeDto';
import { GridSize } from '@material-ui/core';
import { FieldDisplayUiType } from './types/FieldDisplayUiType';

export interface FormFieldDefinition {
    name: string;
    inputAdornment?: any; // Replace 'any' with the actual type if known
    prefix?: string;
    gridSize: GridSize;
}

export interface StepDefinition {
    orderDetails: FormFieldDefinition[];
    furtherInformationDetails?: FormFieldDefinition[];
}

export interface DeliveryDefinition {
    pickupInformation?: StepDefinition;
    deliveryInformation?: StepDefinition;
}

export const COREBOS_DESCRIBE = [
    'SalesOrder',
    'Accounts',
    'Contacts',
    'ContactRole',
    'CobroPago',
    'Documents',
    'HelpDesk',
    'Users',
    'CobroPago',
    'cbQuestion',
    'delivery',
];

export const PHONE_NUMBER_PREFIX = '+39';

export const ORDER_STATUS_PICK_LIST = [
    'Importato',
    'Associato',
    'Da assegnare',
    'Assegnato',
    'Confermato',
    'Cancellato',
    'Declinato',
    'Iniziato',
    'Completato',
    'Annullato',
    'In progresso',
];

// Fields definition structure
export const NEW_ORDER_FIELDS : DeliveryDefinition = {
    pickupInformation: {
        orderDetails: [
            {name: 'store_id', inputAdornment: AccountCircle, prefix: '', gridSize: 12},
            {name: 'referente', inputAdornment: AccountCircle, prefix: '', gridSize: 6},
            {name: 'phone', inputAdornment: PhoneIcon, prefix: '+39', gridSize: 6},
            {name: 'email_task', inputAdornment: MailOutlineIcon, prefix: '', gridSize: 6},
            {name: 'startdate', inputAdornment: ScheduleIcon, prefix: '', gridSize: 6},
            {name: 'task_notes', prefix: '', gridSize: 12},
        ],
    },
    deliveryInformation: {
        orderDetails: [
            {name: 'customer_name', inputAdornment: AccountCircle, prefix: '', gridSize: 6},
            {name: 'phone', inputAdornment: PhoneIcon, prefix: '+39', gridSize: 6},
            {name: 'email_task', inputAdornment: MailOutlineIcon, prefix: '', gridSize: 6},
            {name: 'modalita_di_consegna', prefix: '', gridSize: 6},
            {name: 'customer_address', inputAdornment: LocationOnIcon, prefix: '', gridSize: 12},
        ],
        furtherInformationDetails: [
            {name: 'citoffono', prefix: '', gridSize: 6},
            {name: 'servizio_di_portineria',  prefix: '', gridSize: 6},
            {name: 'tot_small_packages',  prefix: '', gridSize: 4},
            {name: 'tot_medium_packages',  prefix: '', gridSize: 4},
            {name: 'tot_large_packages',  prefix: '', gridSize: 4},
            {name: 'retailerid',  prefix: '', gridSize: 12},
            {name: 'task_notes',  prefix: '', gridSize: 12},
            // Time from, time until add dynamically
        ], //citofono, servizio_di_portineria, tot_large_packages, tot_medium_packages, tot_small_packages, task_notes
    }
};
export const SHOP_TO_SHOP_NEW_ORDER_FIELDS : DeliveryDefinition = {
    pickupInformation: {
        orderDetails: [
            {name: 'store_id', inputAdornment: AccountCircle, prefix: '', gridSize: 12},
            {name: 'referente', inputAdornment: AccountCircle, prefix: '', gridSize: 6},
            {name: 'phone', inputAdornment: PhoneIcon, prefix: '+39', gridSize: 6},
            {name: 'email', inputAdornment: MailOutlineIcon, prefix: '', gridSize: 12},
            {name: 'notes', inputAdornment: '', prefix: '', gridSize: 12},
        ],
    },
    deliveryInformation: {
        orderDetails: [
            {name: 'store_id', inputAdornment: AccountCircle, prefix: '', gridSize: 12},
            {name: 'referente', inputAdornment: AccountCircle, prefix: '', gridSize: 6},
            {name: 'phone', inputAdornment: PhoneIcon, prefix: '+39', gridSize: 6},
            {name: 'email', inputAdornment: MailOutlineIcon, prefix: '', gridSize: 12},
        ],
        furtherInformationDetails: [
            {name: 'tot_small_packages', inputAdornment: null, prefix: '', gridSize: 4},
            {name: 'tot_medium_packages', inputAdornment: null, prefix: '', gridSize: 4},
            {name: 'tot_large_packages', inputAdornment: null, prefix: '', gridSize: 4},
            {name: 'notes', inputAdornment: null, prefix: '', gridSize: 12},
        ], //citofono, servizio_di_portineria, tot_large_packages, tot_medium_packages, tot_small_packages, task_notes
    }
};

export const RETURN_ORDER_FIELDS : DeliveryDefinition = {
    pickupInformation: {
        orderDetails: [
            {name: 'customer_name', inputAdornment: AccountCircle, prefix: '', gridSize: 6},
            {name: 'phone', inputAdornment: PhoneIcon, prefix: '+39', gridSize: 6},
            {name: 'email_task', inputAdornment: MailOutlineIcon, prefix: '', gridSize: 6},
            {name: 'modalita_di_consegna', inputAdornment: null, prefix: '', gridSize: 6},
            // {name: 'startdate', inputAdornment: ScheduleIcon, prefix: '', gridSize: 6},
            
            // Time from, time until add dynamically
            {name: 'customer_address', inputAdornment: LocationOnIcon, prefix: '', gridSize: 12},
        ],
        furtherInformationDetails: [
            {name: 'citoffono', inputAdornment: null, prefix: '', gridSize: 6},
            {name: 'servizio_di_portineria', inputAdornment: null, prefix: '', gridSize: 6},
            {name: 'tot_small_packages', inputAdornment: null, prefix: '', gridSize: 4},
            {name: 'tot_medium_packages', inputAdornment: null, prefix: '', gridSize: 4},
            {name: 'tot_large_packages', inputAdornment: null, prefix: '', gridSize: 4},
            {name: 'retailerid', inputAdornment: null, prefix: '', gridSize: 12},
            {name: 'task_notes', inputAdornment: null, prefix: '', gridSize: 12},
        ], //citofono, servizio_di_portineria, tot_large_packages, tot_medium_packages, tot_small_packages, task_notes
    },
    deliveryInformation: {
        orderDetails: [
            {name: 'store_id', inputAdornment: AccountCircle, prefix: '', gridSize: 12},
            {name: 'saved_add', inputAdornment: AccountCircle, prefix: '', gridSize: 12},
            {name: 'referente', inputAdornment: AccountCircle, prefix: '', gridSize: 6},
            {name: 'phone', inputAdornment: PhoneIcon, prefix: '+39', gridSize: 6},
            {name: 'email_task', inputAdornment: MailOutlineIcon, prefix: '', gridSize: 12},
            {name: 'task_notes', inputAdornment: null, prefix: '', gridSize: 12},
            //{ name: 'modalita_di_consegna', inputAdornment: null, prefix: '', gridSize: 12 },
            /* { name: 'time_until', inputAdornment: ScheduleIcon, prefix: '', gridSize: 6 }, */
        ],
    }
};

export const SMALL_PACKAGE_INFO = [
    'Lato maggiore < 60 cm',
    'Somma delle 3 dimensioni (L+P+H) < 100 cm',
    'Peso < 10 kg'
];

export const MEDIUM_PACKAGE_INFO = [
    'Lato maggiore < 60 cm',
    'Somma delle 3 dimensioni (L+P+H) < 120 cm',
    'Peso < 10 kg'
];

export const LARGE_PACKAGE_INFO = [
    'Lato maggiore < 100 cm',
    'Somma delle 3 dimensioni (L+P+H) < 200 cm',
    'Peso < 20 kg'
];

export const DELIVERY = 'Delivery';
export const PICKUP = 'Pick-up';
export const DELIVERY_PANEL_NAME = 'Delivery';
export const PICKUP_PANEL_NAME = 'Pick-up';
export const excludedTimes = [1, 2, 3, 4, 5, 6, 7, 8, 21, 22, 23];
export const nightExcludedTimes = [21, 22, 23];
export const acceptedMinutes = [0, 15, 30, 45,60 ];
export const stripeKey = 'pk_test_RlI38BaAVLt4IBGGGQWbkEWk';
export const googleMapsApiKey = 'AIzaSyBb0sULiPh59YjfJ8Au97_huAjwAHU4J4o';
export const minimumHour :number = 10; //*used for setting the minimal hour of the day
export const maximumHour :number = 19; //*used for setting the maximal hour of the day
export const maxMinutesInTimeRange :number = 30; //*used for setting the maximal hour minutes of the day
export const CreditCardPaymentMethod = 'Carta';
export const BankTransferPaymentMethod = 'Bonifico'; 

export const staticProductList = [{
    name:'Small'
},{
    name:'Medium'
},{
    name:'Large'
}]

// Fields presentation DTO 
let newOrderFields : FieldInputTypeDto[] = [
    { name: 'phone', mandatory: true,  uitype: FieldDisplayUiType.PhoneInput,editable:true, readonly:false },
    { name: 'referente', mandatory: true, label:'Referente', uitype: FieldDisplayUiType.BaseText,editable:true, readonly:false },
    { name: 'task_notes', mandatory: false, label:'Note', uitype: FieldDisplayUiType.TextArea,editable:true, readonly:false },
    { name: 'startdate', mandatory: true, uitype: FieldDisplayUiType.BaseText ,editable:true, readonly:false},
    { name: 'email_task', mandatory: false, label:'Email', uitype: FieldDisplayUiType.BaseText ,editable:true, readonly:false},
    { name: 'modalita_di_consegna', mandatory: false, label:'Modalità di consegna', defaultValue: 1, 
    type: { 
        picklistValues: [
            { value: "1", label: "Wora Same Day"},
            { value: "3", label: "Wora Flash"},
            { value: "2", label: "Wora Scheduled"},
          ],
          name: "picklist"
    } , uitype:FieldDisplayUiType.SelectInput ,editable:true, readonly:false},
    {   readonly:false, name: "citoffono", label: "Citofono", mandatory: true, type: {"name": "string" }, editable: true,  uitype: FieldDisplayUiType.Text },
    { name: "servizio_di_portineria", label: "Reception service", mandatory: false, defaultValue: false,
        type: {
          picklistValues: [
            { value: true, label: "Si" },
            { value: false, label: "No" }
          ],
          name: "picklist"
        },
        editable: true, uitype: FieldDisplayUiType.SelectInput, readonly:false },
    { name: 'customer_address', mandatory: true, label:'Indirizzo di consegna', uitype: FieldDisplayUiType.BaseText ,editable:true, readonly:false},
    { name: 'customer_name', mandatory: true, label:'Cliente', uitype: FieldDisplayUiType.Text ,editable:true, readonly:false},
    { name: 'time_from', mandatory: true, label:'Dalle', uitype: FieldDisplayUiType.BaseText ,editable:true, readonly:false},
    { name: 'time_until', mandatory: true, label:'Alle', uitype: FieldDisplayUiType.BaseText ,editable:true, readonly:false},
    { name: 'store_id', mandatory: true, label:'Negozio', uitype: FieldDisplayUiType.BaseText ,editable:true, readonly:false},
    { name: "tot_small_packages", label: "CorbosService::small_packages", mandatory: false, type: { name: "string"},editable: true,uitype: FieldDisplayUiType.Text, readonly:false},
    { name: "tot_medium_packages", label: "CorbosService::medium_packages", mandatory: false, type: { name: "string" }, editable: true, uitype: FieldDisplayUiType.Text, readonly:false},
    { name: "tot_large_packages", label: "CorbosService::large_packages", mandatory: false, type: { name: "string" }, editable: true, uitype: FieldDisplayUiType.Text, readonly:false},
    { name: "retailerid", label: "Retailer ID", mandatory: false, type: { name: "string" }, editable: true, uitype: FieldDisplayUiType.Text, readonly:false},
]
let returnOrderFields : FieldInputTypeDto[] = [
    { name: 'phone', mandatory: true,  uitype: FieldDisplayUiType.PhoneInput,editable:true, readonly:false },
    { name: 'referente', mandatory: true, label:'Referente', uitype: FieldDisplayUiType.BaseText,editable:true, readonly:false },
    { name: 'task_notes', mandatory: false, label:'Note', uitype: FieldDisplayUiType.TextArea,editable:true, readonly:false },
    { name: 'startdate', mandatory: true, uitype: FieldDisplayUiType.BaseText ,editable:true, readonly:false},
    { name: 'email_task', mandatory: false, label:'Email', uitype: FieldDisplayUiType.BaseText ,editable:true, readonly:false},
    { name: 'modalita_di_consegna', mandatory: true, label:'Modalità di consegna', defaultValue:1, 
    type: { 
        picklistValues: [
            { value: "1", label: "Wora Same Day"},
            { value: "3", label: "Wora Flash"},
            { value: "2", label: "Wora Scheduled"},
          ],
          name: "picklist"
    } , uitype: FieldDisplayUiType.SelectInput ,editable:true, readonly:false},
    {   readonly:false, name: "citoffono", label: "Citofono", mandatory: true, type: {"name": "string" }, editable: true,  uitype: FieldDisplayUiType.Text },
    { name: "servizio_di_portineria", label: "Reception service", mandatory: false, defaultValue: false,
        type: {
            picklistValues: [
                { value: true, label: "Si" },
                { value: false, label: "No" }
              ],
          name: "picklist"
        },
        editable: true, uitype: FieldDisplayUiType.SelectInput, readonly:false },
    { name: 'customer_address', mandatory: true, label:'Indirizzo di ritiro', uitype: FieldDisplayUiType.BaseText ,editable:true, readonly:false},
    { name: 'customer_name', mandatory: true, label:'Cliente', uitype: FieldDisplayUiType.BaseText ,editable:true, readonly:false},
    { name: 'time_from', mandatory: true, label:'Dalle', uitype: FieldDisplayUiType.BaseText ,editable:true, readonly:false},
    { name: 'time_until', mandatory: true, label:'Alle', uitype: FieldDisplayUiType.BaseText ,editable:true, readonly:false},
    { name: 'store_id', mandatory: true, label:'Negozio', uitype: FieldDisplayUiType.BaseText ,editable:true, readonly:false},
    { name: "tot_small_packages", label: "CorbosService::small_packages", mandatory: false, type: { name: "string"},editable: true,uitype: FieldDisplayUiType.Text, readonly:false},
    { name: "tot_medium_packages", label: "CorbosService::medium_packages", mandatory: false, type: { name: "string" }, editable: true, uitype: FieldDisplayUiType.Text, readonly:false},
    { name: "tot_large_packages", label: "CorbosService::large_packages", mandatory: false, type: { name: "string" }, editable: true, uitype: FieldDisplayUiType.Text, readonly:false},
    { name: "retailerid", label: "Retailer ID", mandatory: false, type: { name: "string" }, editable: true, uitype: FieldDisplayUiType.Text, readonly:false},
]

let shopToShopFields : FieldInputTypeDto[] = [ 
    { name: 'referente', mandatory: true, label:'Referente',  uitype: FieldDisplayUiType.BaseText,editable:true, readonly: false },
    { name: 'email', mandatory: false, label:'Email', uitype: FieldDisplayUiType.BaseText ,editable:true,  readonly: false},
    { name: 'phone', mandatory: true, label:'Telefono',  uitype: FieldDisplayUiType.PhoneInput, editable:true, readonly:false },
    { name: 'notes', mandatory: false, label:'Note', uitype: FieldDisplayUiType.TextArea,editable:true, readonly:false },
    { name: 'store_id', mandatory: true, label:'Negozio', uitype: FieldDisplayUiType.BaseText ,editable:true, readonly:false},
    { name: "tot_small_packages", label: "CorbosService::small_packages", mandatory: false, type: { name: "string"},editable: true,uitype: FieldDisplayUiType.Text, readonly:false},
    { name: "tot_medium_packages", label: "CorbosService::medium_packages", mandatory: false, type: { name: "string" }, editable: true, uitype: FieldDisplayUiType.Text, readonly:false},
    { name: "tot_large_packages", label: "CorbosService::large_packages", mandatory: false, type: { name: "string" }, editable: true, uitype: FieldDisplayUiType.Text, readonly:false},

]
export const FIELD_DESCRIBE = {
    newOrder : newOrderFields,
    returnOrder : returnOrderFields,
    shopToShop: shopToShopFields
}