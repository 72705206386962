import * as React from 'react';
import { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Box } from '@material-ui/core';
import { useStyles, HtmlTooltip, Accordion, AccordionSummary, AccordionDetails } from '../style/Index';
import { useOrderStyles } from './OrderStyle';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { PaymentInfo } from '../payment/PaymentInfo';
import Grid from '@material-ui/core/Grid';
import DatePickerInput from '../utils/DatePickerInput'


import {
    Create,
    SimpleForm,
    SaveButton,
    useNotify,
    useTranslate,
    Notification,
    Toolbar,
    FormDataConsumer,
    useGetIdentity,
    TextInput, required, ReferenceInput, AutocompleteInput, usePermissions, useRedirect, SelectInput
} from 'react-admin';
import FieldInput from '../utils/FieldInput';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import InfoIcon from '@material-ui/icons/Info';
import {
    NEW_ORDER_FIELDS,
    SMALL_PACKAGE_INFO,
    MEDIUM_PACKAGE_INFO,
    LARGE_PACKAGE_INFO,
    DELIVERY_PANEL_NAME,
    PICKUP_PANEL_NAME, googleMapsApiKey, maximumHour, maxMinutesInTimeRange, staticProductList, FIELD_DESCRIBE,
    DeliveryDefinition,
    FormFieldDefinition
} from '../constant';
import {
    getDataFromLocalDb,
    calculatePackageTotalAmount,
    calculatePackageIVAPercentage,
    timeRestrictionSetter,
    formatSearchObject,
    permissionCheck, innerStringWhiteSpaceRemoval, areSelectedDataAndCurrentOneEqual, genereateUid, generateInitialStartdate, abpPermissionCheck
} from '../utils/Helpers';
import { TABLE_DESCRIBE, TABLE_AUTH } from '../utils/local-db';
import ScheduleIcon from "@material-ui/icons/Schedule";
import IconButton from "@material-ui/core/IconButton";
import { Add } from "@material-ui/icons";
import AddresEdit from "../address/AddresEdit";
import { customAlphabet } from 'nanoid'
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByPlaceId } from 'react-google-places-autocomplete';

import { addDays, addHours, setHours, setMinutes, format } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from 'react-router';
import { Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_CurrentUserDto } from '../packages/routing/proxy/src';
import {
    ShopDeliveryService, wora_CorbosService_DeliverySteps_StepType, wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto, wora_CorbosService_DeliverySteps_ShopDeliveryStepDto, wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryUpdateDto,
    wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryCreateDto, wora_CorbosService_DeliverySteps_ShopDeliveryStepCreateDto,
    wora_CorbosService_DeliverySteps_ShopDeliveryStepUpdateDto
} from '../packages/proxy/src';
import { useParams } from "react-router-dom";
import { element } from 'prop-types';
import useAbp from '../useAbp';
import { DeliveryCreateOrEdtiDto, CoordinateDto } from '../types/';
import { da, de } from 'date-fns/locale';
import { abpConfigurationProvider } from '../abp/abpConfigurationProvider';
import { FormDeliveryUpdateOrCreate } from '../types/FormDeliveryUpdateOrCreate';
import { FieldInputTypeDto } from '../types/FieldInputTypeDto';

export const NewOrder = (props: any) => {
    // console.log('NewOrder', props);
    const classes = useStyles();
    const orderClasses = useOrderStyles();
    const translate = useTranslate();
    const abp = useAbp();
    const notify = useNotify();
    const redirect = useRedirect();
    const history = useHistory();
    const { identity } = useGetIdentity();
    const { permissions } = usePermissions();

    const [expanded, setExpanded] = React.useState('');
    const [expandedPanel, setExpandedPanel] = React.useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showPaymentInfo, setShowPaymentInfo] = useState<boolean>(false);
    const [paymentInfo, setPaymentInfo] = useState<any>({});
    const [showAddAddres, setShowAddAddress] = useState<boolean>(false);
    const [fields, setFields] = useState<FieldInputTypeDto[]>([]);
    const [describe, setDescribe] = useState<any>(null);
    const [assignedUserList, setAssignedUserList] = useState<any[]>([]);
    const [massCreateData, setMassCreateData] = useState<any>(null);
    const [extraFields, setExtraFields] = useState<boolean>(false);
    const [oPackages, setPackages] = useState<any>([]);
    const [userAccount, setUserAccount] = useState<any>({});
    const [contactDetails, setContactDetails] = useState<Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_CurrentUserDto>({});
    const [products, setProducts] = useState<any[]>([]);
    const [addressData, setAddressData] = useState<any>();
    const [timeFromDate, setTimeFromDate] = useState<any>(null);
    const [timeUntilDate, setTimeUntilDate] = useState<any>(null);
    const [addressError, setAddressError] = useState<string>('');
    const [generated, setGenerated] = useState<string>(''); //* generated key to make the mandatory  field rerender
    const [wrongAddressValue, setWrongAddressValue] = useState<any>(null);
    const [createdAddressId, setCreatedAddressId] = useState<string>('');
    const [temporaryAddressValue, setTemporaryAddressValue] = useState<any>(null);
    const [emailPickUp, setEmailPickUp] = useState<string>('');

    const [deliveryDefinition, setDeliveryDefinition] = useState<DeliveryDefinition>({});

    const initialStartDate = generateInitialStartdate();
    const [startDate, setStartDate] = useState<any>(generateInitialStartdate());
    const [filterAllShop, setFilterAllShop] = useState<boolean>(false);
    const [record, setRecord] = useState<wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto>({});

    const [deliveryRecord, setDeliveryRecord] = useState<wora_CorbosService_DeliverySteps_ShopDeliveryStepDto>({});
    const [pickupRecord, setPickupRecord] = useState<wora_CorbosService_DeliverySteps_ShopDeliveryStepDto>({});
    const [userInput, setUserInput] = useState<DeliveryCreateOrEdtiDto>({});
    const [houseNumber, setHouseNumber] = useState<string>('');
    const [coordinate, setCoordinate] = useState<CoordinateDto>({});
    

    const { id } = useParams<{ [key: string]: string }>();
    const standardDeliveryType = 1;
    // Check and validete permission
    useEffect(() => {
        // console.log('NowOrder - permission');
        if (typeof permissions !== "undefined") {
            if (!abpPermissionCheck(permissions, 'CorbosService.Deliveries.Create')) {
                redirect('/delivery')
            }
        }
    }, [permissions, redirect])

    useEffect(() => {
        // console.log('Id ->', id);

        if (id !== undefined) {


            const data = props?.record ?? {} as wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto;
            setRecord(data);
            data.steps?.map((step: wora_CorbosService_DeliverySteps_ShopDeliveryStepDto) => {
                if (step.stepType == wora_CorbosService_DeliverySteps_StepType._0) {
                    // console.log('Pickup Record', step);
                    if (step.requested) {
                        setStartDate(new Date(step.requested ?? ''));
                    }

                    setPickupRecord(step);
                } else if (step.stepType == wora_CorbosService_DeliverySteps_StepType._1) {
                    // console.log('Delivery Record', step);
                    setDeliveryRecord(step);
                    setHouseNumber(step.addressNumber ?? '');
                    setCoordinate({ lat: step.lat, lng: step.lng });
                    setTimeFromDate(new Date(step.requested ?? ''));
                    setTimeUntilDate(new Date(step.requestedEnd ?? ''));
                }
            });
            // Reload time from/until
            if (data.deliveryType === 3) {
                // setTimeFromDate(new Date(deliveryRecord.requested ?? ''));
                // setTimeUntilDate(new Date(deliveryRecord.requestedEnd ?? ''));
                setDeliveryDefinition(getTimeFields(data.deliveryType));
            }

            setUserInput((prevState: any) => {
                // console.log('Data from backend', data);
                return {
                    ...prevState,

                    totLargePackages: data.totLargePackages,
                    totMediumPackages: data.totMediumPackages,
                    totSmallPackages: data.totSmallPackages,
                    deliveryType: data.deliveryType,
                    externalOrderCode: data.externalOrderCode,

                    storeId: data.storeId,
                    storeName: data.storeName,
                    concurrencyStamp: data.concurrencyStamp,
                }
            });


        }

    }, [id]);

    const validateRecord = async (values: any) => {
        console.log('Validate Record', values);
        setIsLoading(true);
        let data : FormDeliveryUpdateOrCreate = {};

        // Pickup
        data.pickupStoreId = values[`store_id-${PICKUP_PANEL_NAME}`];
        data.pickupContact = values[`referente-${PICKUP_PANEL_NAME}`];
        data.pickupEmail = values[`email_task-${PICKUP_PANEL_NAME}`];
        const mobileInputPickUp: any = document.getElementById(`phone-${PICKUP_PANEL_NAME}`);
        data.pickupPhone = innerStringWhiteSpaceRemoval(mobileInputPickUp?.value ?? '');
        data.pickupFromDate = format(new Date(startDate ?? initialStartDate), "yyyy-MM-dd HH:mm:ss");
        // console.log('Pickup From Date', data.pickupFromDate);
        data.pickupEmail = values[`email_task-${PICKUP_PANEL_NAME}`];
        data.pickupNote = values[`task_notes-${PICKUP_PANEL_NAME}`];

        // Delivery
        data.deliveryContact = values[`customer_name-${DELIVERY_PANEL_NAME}`];
        const mobileInputDelivery: any = document.getElementById(`phone-${DELIVERY_PANEL_NAME}`);
        data.deliveryPhone = innerStringWhiteSpaceRemoval(mobileInputDelivery?.value ?? '');
        data.deliveryEmail = values[`email_task-${DELIVERY_PANEL_NAME}`];
        data.deliveryType = Number(values[`modalita_di_consegna-${DELIVERY_PANEL_NAME}`]);
        data.deliveryAddress = addressData.label; // values[`customer_address-${DELIVERY_PANEL_NAME}`];
        data.deliveryHouseNumber = houseNumber;
        data.deliveryNote = values[`task_notes-${DELIVERY_PANEL_NAME}`];
        data.reception = values[`servizio_di_portineria-${DELIVERY_PANEL_NAME}`];
        
        if(data.deliveryType === 2){
            let timeFrom = values[`time_from-${DELIVERY_PANEL_NAME}`];
            let timeUntil = values[`time_until-${DELIVERY_PANEL_NAME}`];
            // console.log('Time From', timeFromDate);
            // console.log('Time Until', timeUntilDate);
            data.deliveryFromDate = format(new Date(timeFromDate), "yyyy-MM-dd HH:mm:ss");
            data.deliveryToDate = format(new Date(timeUntilDate), "yyyy-MM-dd HH:mm:ss");
        }
        
        // Global       
        data.paymentStatus = 0; // Not Paid      
        data.interPhone = values[`citoffono-${DELIVERY_PANEL_NAME}`];
        data.retailerid = values[`retailerid-${DELIVERY_PANEL_NAME}`];
        data.tot_large_packages = oPackages.filter((pack: any) => pack.name === 'tot_large_packages')[0]?.count;
        data.tot_small_packages = oPackages.filter((pack: any) => pack.name === 'tot_small_packages')[0]?.count;
        data.tot_medium_packages = oPackages.filter((pack: any) => pack.name === 'tot_medium_packages')[0]?.count;
        data.coordinate = coordinate;

        let hasError: boolean = false;
        let message: string = '';
        if (!data.deliveryAddress) {
            hasError = true;
            message = translate('CorbosService::InvalidAddress');
        }
        if (!data.pickupPhone || !data.deliveryPhone) {
            hasError = true;
            message = message + '\n' + translate('CorbosService::Phone') + ' : ' + translate('ra.validation.required');
        } else if (data.pickupPhone.length < 5 || data.deliveryPhone.length < 5) {
            hasError = true;
            message = message + '\n' + translate('CorbosService::Phone') + ' : ' + translate('ra.validation.required');
        }
        if (data.tot_large_packages === 0 && data.tot_medium_packages === 0 && data.tot_small_packages === 0) {
            hasError = true;
            message = message + '\n' + translate('CorbosService::SelectAtLeastOnePackage');
        }
        if(data.deliveryType === 2 && !data.deliveryFromDate && !data.deliveryToDate ){
            hasError = true;
            message = message + '\n' + translate('CorbosService::SelectDeliveryTimeRange');
        }
        if (hasError) {
            setTimeout(() => {
                notify(message, 'error');
                setIsLoading(false);
            }, 1000);
        } else {
                        
            const paymentInfo: any = {
                subtotal: 0,
                ivaPercentage: 0,
                ivaValue: 0,
                total: 0
            };

            // if (expandedPanel === DELIVERY_PANEL_NAME) {
            //     oPackages.forEach((oPackage: any) => {
            //         values[oPackage.name] = oPackage.count;
            //         let packageSize = '';
            //         if (oPackage.name === 'tot_small_packages') {
            //             packageSize = 'Small';
            //         }
            //         if (oPackage.name === 'tot_medium_packages') {
            //             packageSize = 'Medium';
            //         }
            //         if (oPackage.name === 'tot_large_packages') {
            //             packageSize = 'Large';
            //         }

            //         paymentInfo.subtotal = paymentInfo.subtotal + calculatePackageTotalAmount(oPackage, packageSize, products);
            //         paymentInfo.ivaPercentage = calculatePackageIVAPercentage(packageSize, products);
            //     });
            // }
            // paymentInfo.ivaValue = paymentInfo.subtotal * paymentInfo.ivaPercentage / 100;
            // paymentInfo.total = paymentInfo.ivaValue + paymentInfo.subtotal;
            // values['payment_info_total'] = paymentInfo.total;
            //TODO skip payment info
            // setPaymentInfo(paymentInfo)

            saveRecords(data)
        }
    }

    const saveRecords = async (value: FormDeliveryUpdateOrCreate) => {
        // TODO skip payment info
        //setShowPaymentInfo(true);
        // return;

        setIsLoading(true);

        if (id != undefined) { // Update

            let input: wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryUpdateDto = {}
            input.storeId = value.pickupStoreId;
            input.deliveryType = value.deliveryType; // smae day, scheduled, flash

            input.totLargePackages = value?.tot_large_packages || 0;
            input.totMediumPackages = value?.tot_medium_packages || 0;
            input.totSmallPackages = value?.tot_small_packages || 0;
            input.concurrencyStamp = record.concurrencyStamp; // Concurrency Stamp
            input.externalOrderCode = value.retailerid;

            input.steps = [];

            // Pickup Data
            let pickupStep: wora_CorbosService_DeliverySteps_ShopDeliveryStepUpdateDto = {};
            pickupStep.contactName = value?.pickupContact;
            pickupStep.contactEmail = value.pickupEmail;
            pickupStep.contactPhone = value.pickupPhone;
            pickupStep.timeFrom = value.pickupFromDate ? new Date(value.pickupFromDate).toISOString() : null;
            pickupStep.note = value.pickupNote;
            pickupStep.storeId = value.pickupStoreId;
            pickupStep.id = pickupRecord.id;

            input.steps.push(pickupStep);

            // Delivery Data
            let deliveryStep: wora_CorbosService_DeliverySteps_ShopDeliveryStepUpdateDto = {};

            deliveryStep.reception = value.reception;
            // Call Google to Geocoding
            deliveryStep.fullAddress = value.deliveryAddress;
            deliveryStep.houseNumber = value.deliveryHouseNumber;
            deliveryStep.lat = value.coordinate?.lat;
            deliveryStep.lng = value.coordinate?.lng;
            deliveryStep.contactName = value.deliveryContact;
            deliveryStep.contactEmail = value.deliveryEmail;
            deliveryStep.contactPhone = value.deliveryPhone;
            deliveryStep.interPhone = value?.interPhone;
            deliveryStep.note = value.deliveryNote;
            switch (value.deliveryType) {
                case 1: // Wora Same Day
                case 3: // Wora Flash
                    deliveryStep.timeFrom = value.pickupFromDate ? new Date(value.pickupFromDate).toISOString() : null;
                    break;
                case 2: // Wora Scheduled   
                    deliveryStep.timeFrom = value.deliveryFromDate ? new Date(value.deliveryFromDate).toISOString() : null;
                    deliveryStep.timeFrom = value.deliveryToDate ? new Date(value.deliveryToDate).toISOString() : null;
                    break;
            }

            deliveryStep.id = deliveryRecord.id; // Delivery Step Id

            input.steps.push(deliveryStep);

            // input.businessPartnerId = 1;
            // input.orderDate = new Date().toISOString();

            // console.log('Create Update',input);
            await ShopDeliveryService.putApiShopDeliveries(id, input).then(data => {
                setIsLoading(false);
                history.push('/delivery'); // Go to List
            }).catch(err => {
                setTimeout(() => {
                    notify(translate('CorbosService::SavingError'), 'error');
                    setIsLoading(false);
                }, 1000);
                setIsLoading(false);
            });
        } else {
            let input: wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryCreateDto = {};

            input.orderType = 0; // Normal Order
            input.deliveryType = value.deliveryType; // smae day, scheduled, flash
            input.storeId = value.pickupStoreId;

            input.totLargePackages = value?.tot_large_packages || 0;
            input.totMediumPackages = value?.tot_medium_packages || 0;
            input.totSmallPackages = value?.tot_small_packages || 0;
            input.externalOrderCode = value.retailerid;
            // input.orderDate = value.pickupFromDate ? new Date(value.pickupFromDate).toISOString() : null;

            input.steps = [];

            // Pickup Data
            let pickupStep: wora_CorbosService_DeliverySteps_ShopDeliveryStepCreateDto = {};
            pickupStep.stepType = wora_CorbosService_DeliverySteps_StepType._0;
            pickupStep.contactName = value?.pickupContact;
            pickupStep.contactEmail = value.pickupEmail;
            pickupStep.contactPhone = value.pickupPhone;
            pickupStep.timeFrom = value.pickupFromDate ? new Date(value.pickupFromDate).toISOString() : null;
            pickupStep.note = value.pickupNote
            pickupStep.storeId = value.pickupStoreId;

            input.steps.push(pickupStep);

            // Delivery Data
            let deliveryStep: wora_CorbosService_DeliverySteps_ShopDeliveryStepCreateDto = {};
            deliveryStep.stepType = wora_CorbosService_DeliverySteps_StepType._1;

            // Call Google to Geocoding
            deliveryStep.fullAddress = value.deliveryAddress;
            deliveryStep.houseNumber = value.deliveryHouseNumber;
            deliveryStep.lat = value.coordinate?.lat;
            deliveryStep.lng = value.coordinate?.lng;
            deliveryStep.contactName = value.deliveryContact;
            deliveryStep.contactEmail = value.deliveryEmail;
            deliveryStep.contactPhone = value.deliveryPhone;
            deliveryStep.interPhone = value.interPhone;

            switch (value.deliveryType) {
                case 1: // Wora Same Day
                case 3: // Wora Flash
                    deliveryStep.timeFrom = value.pickupFromDate ? new Date(value.pickupFromDate).toISOString() : null;
                    break;
                case 2: // Wora Scheduled   
                    deliveryStep.timeFrom = value.deliveryFromDate ? new Date(value.deliveryFromDate).toISOString() : null;
                    deliveryStep.timeTo = value.deliveryToDate ? new Date(value.deliveryToDate).toISOString() : null;
                    break;
            }

            deliveryStep.note = value.deliveryNote;

            deliveryStep.reception = value.reception;

            input.steps.push(deliveryStep);

            // console.log('Create Mode',input);
            //TODO disable when payment info
            await ShopDeliveryService.postApiShopDeliveriesCreate(input).then(data => {
                setIsLoading(false);
                history.push('/delivery'); // Go to List
            }).catch(err => {
                setIsLoading(false);
                setTimeout(() => {
                    notify(translate('CorbosService::SavingError'), 'error');
                    setIsLoading(false);
                }, 1000);
            });
        }



    };

    useEffect(() => {
        // console.log('NowOrder Init');
        const contactRoleId = ''; //userdata && (userdata.roles is_admin === 'on' || userdata.rolename === 'Manager') ? '' : shopId;
        const filterAll = false; //userdata && (userdata.is_admin === 'on' || userdata.rolename === 'Manager');
        setFilterAllShop(filterAll);
        setCreatedAddressId(contactRoleId);

        setProducts(staticProductList);
        // console.log('Define Delivery Definition', NEW_ORDER_FIELDS)
        setDeliveryDefinition(getTimeFields(1)); // Set the fields

        let modFields = FIELD_DESCRIBE.newOrder;
        let packageList = modFields.filter((field: any) => field.name === 'tot_small_packages' || field.name === 'tot_medium_packages' || field.name === 'tot_large_packages');
        let packageList2 = packageList.map((field: any, index: number) => {
            let info: string[] = [];
            let unit_price;
            if (field.name === 'tot_small_packages') {
                info = SMALL_PACKAGE_INFO;
                unit_price = products[0]?.unit_price;
            }
            if (field.name === 'tot_medium_packages') {
                info = MEDIUM_PACKAGE_INFO;
                unit_price = products[1]?.unit_price;
            }
            if (field.name === 'tot_large_packages') {
                info = LARGE_PACKAGE_INFO;
                unit_price = products[2]?.unit_price;
            }
            const oPackage: any = oPackages.filter((pack: any) => pack.name === field.name)
            //if the package has count then it will not be zero when the other panel is expanded
            if (oPackage.length > 0) {
                if (oPackage[0]?.count && oPackage[0]?.count > 0) return {
                    name: field.name,
                    label: field.label,
                    count: 0,
                    info: info,
                    index: index,
                    unitPrice: unit_price
                }
            }
            return { name: field.name, label: field.label, count: 0, info: info, index: index, unitPrice: unit_price }
        })

        setPackages(packageList2);
    }, [])

   
    useEffect(() => {
        // console.log('NowOrder - idenitty');
        const userdata = abpConfigurationProvider.getUser();
        const contactDetail = identity && identity.contactDetail;
        if (userdata && id === undefined) {

            pickupRecord.contactName = userdata.name;
            pickupRecord.contactEmail = userdata.email;
            pickupRecord.contactPhone = userdata.phoneNumber;

        }
    }, [abp])

    useEffect(() => {
        // Use to design inteface
        getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result: any) => {
            let modFields = FIELD_DESCRIBE.newOrder;
            setFields(FIELD_DESCRIBE.newOrder);
        })
    }, [expandedPanel]) // eslint-disable-line

    const setCompletAddress = (value: any) => {
        let error = '';
        let hasErr = false;
        // console.log('complte address', value);
        setCoordinate({ lat: value.geometry.location.lat(), lng: value.geometry.location.lng()});
        setUserInput((prevState: any) => {
            return {
                ...prevState,
                latitude: value.geometry.location.lat(),
                longitude: value.geometry.location.lng()
            }
        });

        value.address_components.some((item: any) => {
            if (item.types && Array.isArray(item.types)) {
                item.types.map((element: any) => {
                    switch (element) {
                        case 'postal_code':
                            setUserInput((prevState: any) => {
                                return {
                                    ...prevState,
                                    address: item.short_name,
                                }
                            });
                            break;
                        case 'route':
                            setUserInput((prevState: any) => {
                                return {
                                    ...prevState,
                                    zipCode: item.short_name,
                                }
                            });
                            break;
                        case 'administrative_area_level_2':
                            setUserInput((prevState: any) => {
                                return {
                                    ...prevState,
                                    province: item.short_name,
                                }
                            });
                            break;
                        case 'locality':
                            setUserInput((prevState: any) => {
                                return {
                                    ...prevState,
                                    city: item.short_name,
                                }
                            });
                            break;
                        case 'street_number':
                            setHouseNumber(item.short_name);
                            setUserInput((prevState: any) => {
                                return {
                                    ...prevState,
                                    houseNumber: item.short_name,
                                }
                            });
                            break;
                        case 'country':
                            setUserInput((prevState: any) => {
                                return {
                                    ...prevState,
                                    nation: item.short_name,
                                }
                            });
                            break;
                    }

                });

            }
        });
    }

    const displayAddressBox = (placeHolder: string) => {
        //function to check if the address format is correct
        const updateValues = (data: any, inputAction: any) => {
            // console.log('display', data, inputAction);

            if (inputAction.action == 'select-option') {
                // Call geocoding
                // console.log('palce', data);
                // console.log('palce-id', data.value.place_id);
                geocodeByPlaceId(data.value.place_id)
                    .then(results => {
                        // console.log('result id', results)
                        setCompletAddress(results[0]);
                    }
                    )
                    .catch(error => {
                        console.error(error)
                    }
                    );
            }

            const secondArgument = data.label.split(",")[1];
            const secondArgumentFirstArgument = parseInt(secondArgument?.trim()?.slice(0, 1));
            const firstConditionForAddressValidationToFail: boolean = isNaN(Number(secondArgumentFirstArgument));
            const secondConditionForAddressValidationToFail: boolean = isNaN(Number(secondArgument.split("/")[0])) || isNaN(Number(secondArgument.split("/")[1]));
            if (firstConditionForAddressValidationToFail && secondConditionForAddressValidationToFail) {
                setTemporaryAddressValue(null);
                setWrongAddressValue(data);
                setAddressError(translate('translations.address_validation_error'));
            } else {
                setAddressData(data);
                setAddressError('');
                setWrongAddressValue(null);
                setTemporaryAddressValue(null);
            }



        }
        const checkTheInputChangedValue = (data: any, inputAction: any) => {
            // console.log('check', inputAction, data);
            if (inputAction?.action === 'input-change') {

                //we clear tha value if it is set, so the text in the input can be edited
                if (typeof addressData !== 'undefined' || addressData) {
                    setAddressData(null);
                }
                const secondArgument: any = data.split(",")[1];
                const secondArgumentFirstArgument = parseInt(secondArgument?.trim()?.slice(0, 1));

                //check if the address typed contains the building number
                if (data.split(",").length >= 1 && isNaN(secondArgumentFirstArgument)) {
                    setTemporaryAddressValue(null);
                    setWrongAddressValue({
                        label: data,
                        value: {
                            description: data
                        }
                    });
                    setAddressError(translate('translations.address_validation_error'));
                } else {
                    setWrongAddressValue(null);
                    setTemporaryAddressValue({
                        label: data,
                        value: {
                            description: data
                        }
                    });
                    if (data.split(',').length >= 3) {
                        setAddressError(translate('translations.select_address_option'))
                    }
                }
            }
        }
        return (
            <div key={'addressFieldBox'} style={{ marginBottom: '40px' }}>
                <GooglePlacesAutocomplete
                    apiKey={googleMapsApiKey}
                    selectProps={{
                        value: wrongAddressValue ? wrongAddressValue : addressData,
                        onChange: updateValues,
                        inputName: 'customer_address',
                        placeholder: placeHolder,
                        filterOption: (v: any) => {
                            const secondArgument = parseInt(v.label.split(",")[1]);
                            if (!isNaN(secondArgument)) {
                                //if the second argumwent is a number, it is correct.
                                return v;
                            }
                        },
                        onInputChange: checkTheInputChangedValue,
                        inputValue: addressData?.label! ? addressData?.label! : wrongAddressValue ? wrongAddressValue?.label! : temporaryAddressValue?.label!,
                        closeMenuOnSelect: false,
                        styles: {
                            container: (provided: any, state: any) => ({
                                ...provided,
                                border: addressError && '1px solid red',
                                zIndex: state?.isFocused ? 2 : 1,
                            }),
                            control: (provided: any, state: any) => ({
                                ...provided,
                                zIndex: state?.isFocused ? 2 : 1,
                            }),
                            placeholder: (provided: any) => ({
                                ...provided,
                                color: '#BDBDBD'
                            })
                        }
                    }}
                    autocompletionRequest={{
                        componentRestrictions: {
                            country: ['it'],
                        }
                    }}
                />
                {addressError && <Box display={'flex'} width={'100%'} px={1} my={1} textAlign={'start'}
                    style={{ color: 'red' }}>{addressError}</Box>}
            </div>
        );
    }

    const updatePackageCount = (indexValue: number, value: number) => {
        // console.log('updatePackageCount', indexValue, value);
        const newPackages = oPackages;
        newPackages[indexValue].count = newPackages[indexValue].count + value;
        newPackages[indexValue].count = newPackages[indexValue].count >= 0 ? newPackages[indexValue].count : 0;

        if (indexValue == 0) {
            setUserInput((prevState: any) => {
                return { ...prevState, totSmallPackages: newPackages[indexValue].count }
            });
        } else if (indexValue == 1) {
            setUserInput((prevState: any) => {
                return { ...prevState, totMediumPackages: newPackages[indexValue].count }
            });
        } else if (indexValue == 2) {
            setUserInput((prevState: any) => {
                return { ...prevState, totLargePackages: newPackages[indexValue].count }
            });
        }

        setPackages([...newPackages]);
    }

    const handleChange = (panel: any) => (event: any, newExpanded: any) => {
        setExpandedPanel(panel);
        setExpanded(newExpanded ? panel : false);
    };

    const displayPackageBox = (packageName: any, itemNumber: number = 0) => {
        let packageInfo = oPackages.filter((opackage: any) => opackage.name === packageName)[0];
        packageInfo = packageInfo ?? {};
        packageInfo.count = itemNumber;
        return (
            <Box py={1} px={0.2} mt={1} mb={4} className={orderClasses.packageBox}>
                <Typography component="div" align="center">
                    <Box fontSize="small" justifyContent="center">
                        <Box>
                            <span className={orderClasses.packageTitleBox}>{translate(packageInfo?.label)} </span>
                            <HtmlTooltip
                                title={
                                    <React.Fragment>
                                        {
                                            packageInfo?.info?.map((info: string, index: number) => {
                                                return (
                                                    <Box key={index}> - <span>{`${info}`}</span> </Box>
                                                )
                                            })
                                        }
                                    </React.Fragment>
                                }

                            >
                                <InfoIcon className={orderClasses.packageBoxInfoIcon} />
                            </HtmlTooltip>
                        </Box>
                    </Box>
                </Typography>
                <Typography component="div">
                    <Box mt={1} display="flex" justifyContent="center">
                        <Typography className={orderClasses.packageBoxCounterBtn}
                            onClick={() => updatePackageCount(packageInfo?.index, -1)}>
                            <RemoveCircleOutlineIcon /> </Typography>
                        <Typography> <span id={packageInfo.name + '-count'}
                            className={orderClasses.packageBoxCounter}> {packageInfo?.count} </span>
                        </Typography>
                        <Typography className={orderClasses.packageBoxCounterBtn}
                            onClick={() => updatePackageCount(packageInfo?.index, 1)}> <AddCircleOutlineIcon />
                        </Typography>
                    </Box>
                </Typography>
            </Box>
        )
    }

    const CreateToolbar = (props: any) => {
        return (
            <Toolbar {...props} className={orderClasses.actionToolbar}
                style={{ backgroundColor: '#fff', padding: '0', position: 'relative' }}>
                <SaveButton
                    label={(id == undefined ? translate('CorbosService::GoToPayment') : translate('CorbosService::Save'))}
                    icon={isLoading ? <CircularProgress color={isLoading ? 'primary' : 'secondary'} size={24} /> : <></>}
                    //submitOnEnter={true}
                    disabled={isLoading}
                    onClick={() => setGenerated(genereateUid())} //*uid to change the key of the grid to rerender the mandatory fields
                    redirect={false}
                    fullWidth
                    size="small"
                    className={classes.toolbarBtn}
                    {...props}
                />
            </Toolbar>
        );
    }

    const getTimeFields = (status: Number) : DeliveryDefinition => {
        console.log('getTimeFields', status);
        if (status == 2 && !deliveryDefinition.deliveryInformation?.orderDetails.filter(e => e.name === 'time_from').length) {

            // setExtraFields(true);
            const newOrderDetails = [...(deliveryDefinition.deliveryInformation?.orderDetails || [])];
            newOrderDetails.splice(4, 0);
            newOrderDetails.splice(
                4, 0,
                { name: 'time_from', inputAdornment: ScheduleIcon, prefix: '', gridSize: 6 }
            );
            newOrderDetails.splice(
                5, 0,
                { name: 'time_until', inputAdornment: ScheduleIcon, prefix: '', gridSize: 6 }
            );
            const newDeliveryDefinition = {
                ...deliveryDefinition,
                deliveryInformation: {
                    ...deliveryDefinition.deliveryInformation,
                    orderDetails: newOrderDetails
                }
            };

            return newDeliveryDefinition

        } else {
            return NEW_ORDER_FIELDS;
        }
    }

    const handleFormValuesChange = (formDataValues: any) => {
        // console.log('handleFormValuesChange', formDataValues);
        setTimeout(() => {
            const servizioDiPortineriaBox: any = document.getElementById('servizioDiPortineriaBox');
            if (servizioDiPortineriaBox) {
                if (formDataValues && formDataValues.servizio_di_portineria === 'Si') {
                    servizioDiPortineriaBox.style.display = 'block';
                } else {
                    servizioDiPortineriaBox.style.display = 'none';
                }
            }
            if (formDataValues){
                //check if the value is Wora Scheduled Delivery, if true and time_from and time_until fields are not present show them
                let status = formDataValues && formDataValues[`modalita_di_consegna-${DELIVERY_PANEL_NAME}`] ? Number(formDataValues[`modalita_di_consegna-${DELIVERY_PANEL_NAME}`]) : 0;
                if (formDataValues && status === 2 &&
                    !deliveryDefinition.deliveryInformation?.orderDetails.filter(e => e.name === 'time_from').length) {
                        
                        setDeliveryDefinition(getTimeFields(status));
                        //     deliveryDefinition.deliveryInformation?.orderDetails.splice(
                        //     4, 0,
                        //     { name: 'time_from', inputAdornment: ScheduleIcon, prefix: '', gridSize: 6 }
                        // );
                        // deliveryDefinition.deliveryInformation?.orderDetails.splice(
                        //     5, 0,
                        //     { name: 'time_until', inputAdornment: ScheduleIcon, prefix: '', gridSize: 6 }
                        // );
                        setExtraFields(true);
                }
                //remove the time_from and time_until fields
                if (formDataValues && status !== 2 && extraFields) {
                    // if (deliveryDefinition.deliveryInformation && deliveryDefinition.deliveryInformation?.orderDetails.filter(e => e.name === 'time_from').length > 0) {
                    //     deliveryDefinition.deliveryInformation?.orderDetails.splice(4, 2);
                    // }
                    setDeliveryDefinition(getTimeFields(status));
                    setExtraFields(false);
                }
            }
            
            
        }, 200);
    }

    return (
        <>
            {
                showPaymentInfo
                    ?
                    <PaymentInfo
                        packages={oPackages}
                        account={userAccount}
                        massCreateData={massCreateData}
                        paymentinfo={paymentInfo}
                        {...props} />
                    : <div className={orderClasses.root}>
                        <Card className={classes.appCard} variant="outlined">
                            <CardContent>
                                <Typography component="div" variant="h6">
                                    <Box fontWeight="fontWeightBold" textAlign="center" py="1rem">
                                    {id ? translate('CorbosService::EditDelivery') : translate('CorbosService::CreateDelivery')} {translate('CorbosService::Normal')}
                                    </Box>
                                </Typography>

                                <Box py="1rem" mt="-1.6rem">

                                    <Create {...props} component="div">
                                        <SimpleForm save={validateRecord} toolbar={<CreateToolbar />}>
                                            {/* PICKUP */}
                                            <Accordion square expanded={expanded === PICKUP_PANEL_NAME}
                                                onChange={handleChange(PICKUP_PANEL_NAME)} style={{ width: '100%' }}>
                                                <AccordionSummary aria-controls={PICKUP_PANEL_NAME + '-content'}
                                                    id={PICKUP_PANEL_NAME + '-header'}>
                                                    <Grid container spacing={0} alignItems="center">
                                                        <Grid item xs={2} md={2}>
                                                            <Box mt={0.4}> {expanded === PICKUP_PANEL_NAME ?
                                                                <ExpandLessIcon /> : <ExpandMoreIcon />} </Box>
                                                        </Grid>
                                                        <Grid item xs={8} md={8}>
                                                            <Typography
                                                                align={'center'}> {translate('CorbosService::PickUp')}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <div className={classes.accordionContent}>

                                                    <AccordionDetails>
                                                        <Box mt="-1rem" textAlign="center">
                                                            <Typography component="div">
                                                                {translate('AbpIdentity::Details')}
                                                            </Typography>
                                                        </Box>
                                                        <Box mt={4}>
                                                            <Grid container spacing={1}>

                                                                {
                                                                    deliveryDefinition.pickupInformation?.orderDetails.map((orderInfo: FormFieldDefinition) => {

                                                                        let input: any = null;
                                                                        let key : string = '';
                                                                        const found = fields.filter((field: FieldInputTypeDto) => field.name === orderInfo.name);
                                                                        if (found.length > 0) {
                                                                            const fieldName = found[0].name;
                                                                                                                                                        
                                                                            const fieldDefinition = JSON.parse(JSON.stringify(found[0])) as FieldInputTypeDto;
                                                                            fieldDefinition.name = fieldDefinition.name  + `-${PICKUP_PANEL_NAME}`;
                                                                            const extraProps: any = {
                                                                                placeholder: found[0].label,
                                                                                label: '',
                                                                                elementId: found[0].name + `-${PICKUP_PANEL_NAME}`,
                                                                                prefix: orderInfo.prefix,
                                                                                onChange: null,
                                                                                inputAdornment: orderInfo.inputAdornment ?
                                                                                    <orderInfo.inputAdornment /> : <> </>
                                                                            };
                                                                            if (fieldName === 'store_id') {
                                                                                // console.log('store_id', found[0].name);
                                                                                const key = expanded === PICKUP_PANEL_NAME ? fieldDefinition.name + 'Gridupdate' : fieldDefinition.name + 'Grid'; //change the key to update the values
                                                                                return (
                                                                                    <Grid key={key} item
                                                                                        xs={12} md={orderInfo.gridSize}>
                                                                                        <Box mt={-2} display={'inline-flex'}
                                                                                            width={'100%'}>
                                                                                            <ReferenceInput
                                                                                                variant="outlined"
                                                                                                key={fieldDefinition.name}
                                                                                                label={extraProps.placeholder ?? fieldDefinition.label}
                                                                                                placeholder={fieldDefinition.label ?? ''}
                                                                                                source={fieldDefinition.name}
                                                                                                fullWidth
                                                                                                size={'md'}
                                                                                                reference={'ContactRole'}
                                                                                                alwaysOn={true}
                                                                                                filter={filterAllShop ? {} : { id: createdAddressId }}
                                                                                                filterToQuery={searchText =>
                                                                                                    formatSearchObject('name', searchText)
                                                                                                }
                                                                                                validate={required()}
                                                                                            >
                                                                                                <AutocompleteInput
                                                                                                    defaultValue={record.storeId}
                                                                                                    variant="outlined"
                                                                                                    key={'ref' + fieldDefinition.name}
                                                                                                    optionText={'name'}
                                                                                                />
                                                                                            </ReferenceInput>
                                                                                            {(expanded && abpPermissionCheck(permissions, 'ContactRole')) &&
                                                                                                (<IconButton
                                                                                                    className={orderClasses.addAddressButton}
                                                                                                    color={'default'}
                                                                                                    size={"small"}
                                                                                                    onClick={() => {
                                                                                                        setShowAddAddress(true);
                                                                                                        setExpanded('')
                                                                                                    }}>
                                                                                                    <Add />
                                                                                                </IconButton>)
                                                                                            }

                                                                                        </Box>
                                                                                    </Grid>)
                                                                            }
                                                                            if (fieldName === 'referente') {
                                                                                fieldDefinition.defaultValue = ((pickupRecord?.contactName == undefined) ? undefined : pickupRecord?.contactName);
                                                                            }

                                                                            if (fieldName === 'task_notes') {
                                                                                fieldDefinition.defaultValue = pickupRecord.note;
                                                                            }
                                                                            if (fieldName === 'startdate') {
                                                                                // console.log('startdate', startDate, pickupRecord.requested);
                                                                                let maxTime = setHours(setMinutes(new Date(), maxMinutesInTimeRange), maximumHour);
                                                                                const timeRestrictionObject = timeRestrictionSetter(startDate, maxTime, new Date(), initialStartDate);
                                                                                const minTime = timeRestrictionObject.minTime;
                                                                                const minDate = timeRestrictionObject.minDate;
                                                                                maxTime = timeRestrictionObject.maxTime;
                                                                                const selectedDate = timeRestrictionObject.selectedDate;
                                                                                if (selectedDate && !areSelectedDataAndCurrentOneEqual(selectedDate, startDate)) setStartDate(selectedDate);

                                                                                return (
                                                                                    <Grid key={fieldDefinition.name + 'Grid'} item
                                                                                        xs={12} md={orderInfo.gridSize}
                                                                                        className={orderClasses.responsiveGrid}>
                                                                                        <Box mt={-0.5}>
                                                                                            <DatePickerInput
                                                                                                fieldName={fieldDefinition.name}
                                                                                                fieldLabel={fieldDefinition.label ?? ''}
                                                                                                selectedDate={selectedDate ?? initialStartDate}
                                                                                                setSelectedDate={(date: any) => setStartDate(date)}
                                                                                                minDateRestriction={minDate}
                                                                                                maxDateRestriction={addDays(new Date(), 1)}
                                                                                                minTimeRestriction={minTime}
                                                                                                maxTimeRestriction={maxTime}
                                                                                                initialDate={initialStartDate}
                                                                                            />
                                                                                        </Box>

                                                                                    </Grid>
                                                                                )
                                                                            }
                                                                            if (fieldName === 'email_task') {
                                                                                fieldDefinition.defaultValue = pickupRecord.contactEmail;
                                                                                extraProps.onChange = (e: any) => {
                                                                                    setEmailPickUp(e.target.value)
                                                                                }
                                                                            }
                                                                            if (fieldName === 'phone') {
                                                                                fieldDefinition.defaultValue = pickupRecord?.contactPhone;
                                                                                input = <Box mt={1.5} mb={4.5}
                                                                                    className={'PhoneInputBox'}>
                                                                                    {
                                                                                        FieldInput(fieldDefinition, extraProps, '', describe, assignedUserList)
                                                                                    }
                                                                                </Box>
                                                                                
                                                                            } else {
                                                                                input = FieldInput(fieldDefinition, extraProps, '', describe, assignedUserList);
                                                                            }
                                                                        }

                                                                        return (
                                                                            React.Children.toArray(
                                                                                <>
                                                                                    {input
                                                                                        &&
                                                                                        <Grid key={key + 'Grid'}
                                                                                            item
                                                                                            xs={12}
                                                                                            md={orderInfo.gridSize}>
                                                                                            <Box mt={-2}>{input}</Box>
                                                                                        </Grid>
                                                                                    }
                                                                                </>
                                                                            )
                                                                        );
                                                                    })
                                                                }
                                                            </Grid>
                                                        </Box>
                                                    </AccordionDetails>
                                                </div>
                                                {/* <pre>{JSON.stringify(pickupRecord, null, 2)}</pre> */}
                                            </Accordion>
                                            {/* DELIVERY */}
                                            <Accordion square expanded={expanded === DELIVERY_PANEL_NAME}
                                                onChange={handleChange(DELIVERY_PANEL_NAME)} style={{ width: '100%' }}>
                                                <AccordionSummary aria-controls={DELIVERY_PANEL_NAME + '-content'}
                                                    id={DELIVERY_PANEL_NAME + '-header'}>
                                                    <Grid container spacing={0} alignItems="center">
                                                        <Grid item xs={2} md={2}>
                                                            <Box mt={0.4}> {expanded === DELIVERY_PANEL_NAME ?
                                                                <ExpandLessIcon /> : <ExpandMoreIcon />} </Box>
                                                        </Grid>
                                                        <Grid item xs={8} md={8}>
                                                            <Typography
                                                                align={'center'}> {translate('CorbosService::Delivery')} </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <div className={classes.accordionContent}>
                                                    <AccordionDetails>
                                                        <Box mt="-1rem" textAlign="center">
                                                            <Typography component="div">
                                                                {translate('AbpIdentity::Details')}
                                                            </Typography>
                                                        </Box>

                                                        <Box mt={4}>
                                                            <Grid container spacing={1}>

                                                                {
                                                                    deliveryDefinition.deliveryInformation?.orderDetails.map((orderInfo: FormFieldDefinition) => {
                                                                        // console.log('orderInfo', orderInfo.name);
                                                                        let input: any = null;
                                                                        let key : string = '';
                                                                        const found = fields.filter((field: FieldInputTypeDto) => field.name === orderInfo.name);
                                                                        if (found.length > 0) {
                                                                            // console.log('found', found);
                                                                            const fieldName = found[0].name;
                                                                            const fieldDefinition = JSON.parse(JSON.stringify(found[0])) as FieldInputTypeDto;
                                                                            fieldDefinition.name = fieldDefinition.name  + `-${DELIVERY_PANEL_NAME}`;
                                                                            
                                                                            const extraProps: any = {
                                                                                placeholder: found[0].label,
                                                                                label: '',
                                                                                elementId: found[0].name  + `-${DELIVERY_PANEL_NAME}`,
                                                                                prefix: orderInfo.prefix,
                                                                                onChange: null,
                                                                                inputAdornment: orderInfo.inputAdornment ?
                                                                                    <orderInfo.inputAdornment /> : <> </>
                                                                            };

                                                                            
                                                                            if (fieldName === 'modalita_di_consegna') {
                                                                                // console.log('modalit di consgna #########');
                                                                                if (fieldDefinition.type && fieldDefinition.type.picklistValues) {
                                                                                    fieldDefinition.type.picklistValues = fieldDefinition.type.picklistValues.filter((pickItem: any) => pickItem.value !== "--None--");
                                                                                    fieldDefinition.defaultValue = userInput?.deliveryType ?? standardDeliveryType;
                                                                                    // fieldDefinition.source = userInput?.deliveryType;
                                                                                }
                                                                            }
                                                                            if (fieldName === 'customer_address') {

                                                                                if (typeof deliveryRecord?.address !== 'undefined' && typeof addressData === "undefined") {
                                                                                    setAddressData({
                                                                                        label: deliveryRecord?.contactAddress,
                                                                                        value: {
                                                                                            description: deliveryRecord?.contactAddress,
                                                                                        }
                                                                                    });
                                                                                }
                                                                                return <Grid key={fieldDefinition.name + 'Grid'}
                                                                                    item xs={12}
                                                                                    md={orderInfo.gridSize}>
                                                                                    <Box>
                                                                                        <TextInput
                                                                                            variant="outlined"
                                                                                            key={fieldDefinition.name}
                                                                                            label={''}
                                                                                            placeholder={fieldDefinition.label ?? fieldDefinition.label}
                                                                                            source={fieldDefinition.name}
                                                                                            defaultValue={addressData?.label!}
                                                                                            validate={required('Field is required')}
                                                                                            fullWidth
                                                                                            style={{ display: 'none' }}
                                                                                        />

                                                                                        {displayAddressBox(fieldDefinition.label ?? '')}
                                                                                    </Box>
                                                                                </Grid>

                                                                            }


                                                                            if (fieldName === 'customer_name') {
                                                                                fieldDefinition.defaultValue = deliveryRecord.contactName;
                                                                            }
                                                                            if (fieldName === 'email_task') {
                                                                                extraProps.label = `${fieldDefinition.label}`;
                                                                                fieldDefinition.defaultValue = deliveryRecord.contactEmail;
                                                                            }

                                                                            if (fieldName === 'time_from') {

                                                                                let maxTime = setHours(setMinutes(new Date(), maxMinutesInTimeRange), maximumHour);
                                                                                let minTime = startDate ? addHours(startDate, 1) : null;
                                                                                const timeRestrictionObject = timeRestrictionSetter(timeFromDate, maxTime, minTime);
                                                                                minTime = timeRestrictionObject.minTime;
                                                                                maxTime = timeRestrictionObject.maxTime;
                                                                                const minDate = timeRestrictionObject.minDate;
                                                                                const selectedDate = timeRestrictionObject.selectedDate;
                                                                                if (selectedDate && !areSelectedDataAndCurrentOneEqual(selectedDate, timeFromDate)) {
                                                                                    setTimeFromDate(selectedDate);
                                                                                };

                                                                                return (
                                                                                    <Grid key={fieldDefinition.name + 'Grid'} item
                                                                                        xs={12} md={orderInfo.gridSize}
                                                                                        className={orderClasses.responsiveGrid}>
                                                                                        <Box mt={-3}>
                                                                                            <DatePickerInput
                                                                                                fieldName={fieldDefinition.name}
                                                                                                fieldLabel={fieldDefinition.label ?? ''}
                                                                                                selectedDate={selectedDate}
                                                                                                setSelectedDate={(date: any) => {
                                                                                                    setTimeFromDate(date);
                                                                                                    setTimeUntilDate(date);
                                                                                                }}
                                                                                                minDateRestriction={minDate}
                                                                                                maxDateRestriction={addDays(new Date(), 1)}
                                                                                                minTimeRestriction={minTime}
                                                                                                maxTimeRestriction={maxTime}
                                                                                            />

                                                                                        </Box>
                                                                                    </Grid>
                                                                                );
                                                                            }
                                                                            if (fieldName === 'time_until') {

                                                                                let maxTime = setHours(setMinutes(new Date(), maxMinutesInTimeRange), maximumHour);
                                                                                let minTime = timeFromDate ? addHours(timeFromDate, 1) : null;

                                                                                const timeRestrictionObject = timeRestrictionSetter(timeUntilDate, maxTime, minTime);
                                                                                maxTime = timeRestrictionObject.maxTime;
                                                                                minTime = timeRestrictionObject.minTime;
                                                                                const minDate = timeRestrictionObject.minDate;
                                                                                const selectedDate = timeRestrictionObject.selectedDate;
                                                                                if (selectedDate && !areSelectedDataAndCurrentOneEqual(selectedDate, timeUntilDate)) setTimeUntilDate(selectedDate);

                                                                                return (
                                                                                    <Grid key={fieldDefinition.name + 'Grid'} item
                                                                                        xs={12} md={orderInfo.gridSize}
                                                                                        className={orderClasses.responsiveGrid}>
                                                                                        <Box mt={-3}>
                                                                                            <DatePickerInput
                                                                                                fieldName={fieldDefinition.name}
                                                                                                fieldLabel={fieldDefinition.label ?? ''}
                                                                                                selectedDate={selectedDate}
                                                                                                setSelectedDate={(date: any) => {
                                                                                                    setTimeUntilDate(date);
                                                                                                }}
                                                                                                minDateRestriction={minDate}
                                                                                                maxDateRestriction={addDays(new Date(), 1)}
                                                                                                minTimeRestriction={minTime}
                                                                                                maxTimeRestriction={maxTime}
                                                                                            />

                                                                                        </Box>
                                                                                    </Grid>
                                                                                );
                                                                            }

                                                                            if (fieldName === 'phone') {
                                                                                fieldDefinition.defaultValue = deliveryRecord.contactPhone;
                                                                                
                                                                                input = <Box mt={1.5} mb={4.5}
                                                                                    className={'PhoneInputBox'}>
                                                                                    {
                                                                                        FieldInput(fieldDefinition, extraProps, '', describe, assignedUserList)
                                                                                    }
                                                                                </Box>
                                                                                
                                                                            } else {
                                                                                input = FieldInput(fieldDefinition, extraProps, '', describe, assignedUserList);
                                                                            }
                                                                            if (fieldName === 'email_task') {
                                                                                fieldDefinition.defaultValue = deliveryRecord.contactEmail;
                                                                            }

                                                                        }
                                                                        // const _key = found[0]?.mandatory ? found[0]?.name + 'Grid' + generated : found[0]?.name;
                                                                        // console.log('key', input);
                                                                        return (
                                                                            React.Children.toArray(
                                                                                <>
                                                                                    {input
                                                                                        &&
                                                                                        <Grid key={key}
                                                                                            item xs={12}
                                                                                            md={orderInfo.gridSize}>
                                                                                            <Box mt={-3}>{input}</Box>
                                                                                        </Grid>
                                                                                    }
                                                                                </>
                                                                            )
                                                                        );
                                                                    })
                                                                }
                                                            </Grid>

                                                            <Box mt="-1rem" textAlign="center">
                                                                <Typography component="div">
                                                                    {translate('CorbosService::OtherInfo')}
                                                                </Typography>
                                                            </Box>

                                                            <Box mt={4}>
                                                                <Grid container spacing={1}>

                                                                    {
                                                                        deliveryDefinition.deliveryInformation?.furtherInformationDetails?.map((orderInfo: FormFieldDefinition) => {

                                                                            let input: any = null;
                                                                            let key : string = '';
                                                                            const found = fields.filter((field: any) => field.name === orderInfo.name);
                                                                            if (found.length > 0) {
                                                                                
                                                                                const fieldName = found[0].name;
                                                                                key = found[0]?.mandatory ? found[0]?.name + 'Grid' + generated : found[0]?.name;
                                                                                const fieldDefinition = JSON.parse(JSON.stringify(found[0])) as FieldInputTypeDto;
                                                                                fieldDefinition.name = fieldDefinition.name  + `-${DELIVERY_PANEL_NAME}`;
                                                                                
                                                                                const extraProps: any = {
                                                                                    placeholder: found[0].label,
                                                                                    label: '',
                                                                                    elementId: found[0].name + `-${DELIVERY_PANEL_NAME}`,
                                                                                    prefix: orderInfo.prefix,
                                                                                    onChange: null,
                                                                                    inputAdornment: orderInfo.inputAdornment ?
                                                                                        <orderInfo.inputAdornment /> : <> </>
                                                                                };
                                                                                
                                                                                if (fieldName === 'task_notes') {
                                                                                    fieldDefinition.defaultValue = deliveryRecord.note;
                                                                                }
                                                                                if (fieldName === "retailerid") {
                                                                                    
                                                                                    fieldDefinition.defaultValue = userInput.externalOrderCode;
                                                                                }
                                                                                if (fieldName === "citoffono") {
                                                                                    fieldDefinition.defaultValue = deliveryRecord.interPhone;
                                                                                }
                                                                                if (fieldName === "servizio_di_portineria") {
                                                                                    let receptionStatus = deliveryRecord.reception ?? false;
                                                                                    fieldDefinition.defaultValue = receptionStatus;
                                                                                }
                                                                                if (fieldName.startsWith('tot_')) {
                                                                                    let defValue = 0;
                                                                                    if (fieldName === 'tot_small_packages') {
                                                                                        defValue = userInput.totSmallPackages ?? 0;
                                                                                    }
                                                                                    else if (fieldName === 'tot_medium_packages') {
                                                                                        defValue = userInput.totMediumPackages ?? 0;
        
                                                                                    }
                                                                                    else if (fieldName === 'tot_large_packages') {
                                                                                        defValue = userInput.totLargePackages ?? 0;
                                                                                    }
                                                                                    input = displayPackageBox(orderInfo.name, defValue);
                                                                                } else {
                                                                                    // const extraProps = {
                                                                                    //     placeholder: fieldDefinition.label,
                                                                                    //     classname: '',
                                                                                    //     label: '',
                                                                                    //     prefix: orderInfo.prefix,
                                                                                    //     inputAdornment: orderInfo.inputAdornment ?
                                                                                    //         <orderInfo.inputAdornment /> : <></>
                                                                                    // };
                                                                                    input = FieldInput(fieldDefinition, extraProps, '', describe, assignedUserList);
                                                                                }

                                                                            }
                                                                            // const _key = found[0]?.mandatory ? found[0]?.name + generated : found[0]?.name;
                                                                            return (
                                                                                React.Children.toArray(
                                                                                    <>
                                                                                        {input
                                                                                            ? <Grid key={key} item
                                                                                                xs={12}
                                                                                                md={orderInfo.gridSize}>
                                                                                                <Box mt={-3}> {input} </Box>
                                                                                            </Grid>
                                                                                            : null
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            );
                                                                        })
                                                                    }
                                                                </Grid>
                                                            </Box>
                                                        </Box>
                                                    </AccordionDetails>
                                                </div>
                                                {/* <pre>{JSON.stringify(deliveryRecord, null, 2)}</pre> */}
                                            </Accordion>

                                            <FormDataConsumer>
                                                {({ formData }) => {
                                                    handleFormValuesChange(formData)
                                                    return (<></>);
                                                }}
                                            </FormDataConsumer>
                                        </SimpleForm>
                                    </Create>

                                    <Notification />
                                </Box>

                            </CardContent>
                        </Card>
                    </div>

            }
            {showAddAddres ?
                (<Box className={'modal'}>
                    <div onClick={() => {
                        setShowAddAddress(false);
                        setExpanded(PICKUP_PANEL_NAME)
                    }} className="overlay"></div>
                    <Box display={'flex'} flexDirection={'column'} className={'modal-content'} p={7.5}
                        style={{ width: '40%' }}>
                        <AddresEdit {...props} resource={'ContactRole'} togglePopUp={(createdAddress?: string) => {
                            setShowAddAddress(false);
                            setExpanded(PICKUP_PANEL_NAME);
                            if (createdAddress) setCreatedAddressId(createdAddress);
                        }} />
                    </Box>

                </Box>) : ''}
        </>
    )
};
